<template>
  <div class="organ-management-page" v-loading="loading">
    <!-- <el-row> -->
    <!-- <el-col :span="24"> -->
    <el-card shadow="always">
      <el-form :inline="true" :model="form" class="demo-form-inline search searchFrom" size="small">
        <el-form-item label="客户姓名:" style="margin-right: -200px">
          <el-input v-model="form.customerName" :clearable="true" placeholder="请输入客户名称"></el-input>
        </el-form-item>
        <el-form-item label="客户电话:" style="margin-right: -20px">
          <el-input
            v-model="form.customerPhone"
            :clearable="true"
            placeholder="请输入客户电话"
            oninput="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item label="上门日期:" style="margin-right: -40px">
          <el-date-picker
            v-model="form.date1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            style="width: 86%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="任务状态:">
          <el-select v-model="form.region" placeholder="请选择任务状态">
            <el-option label="全部" value=""></el-option>
            <el-option label="已确认预约" value="301"></el-option>
            <el-option label="2小时内上门" value="302"></el-option>
            <el-option label="已采样" value="401"></el-option>
            <el-option label="已送检" value="501"></el-option>
            <el-option label="已完成" value="601"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search" icon="el-icon-search" :disabled="disabledFalg">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- </el-col> -->
    <!-- </el-row> -->
    <!-- 任务列表 -->
    <el-card shadow="always" class="card" style="margin-top: 20px;">
      <div class="table-block" style="margin: 0">
        <el-button type="danger" @click="handleDelete" plain icon="el-icon-delete" style="margin: -20px 0 20px"
          >删除</el-button
        >
        <div class="Selectnum">已选中<span style="color:#5479FF">{{multipleSelection.length}}</span>条信息</div>
        <el-table
          :data="tableData"
          ref="multipleTable"
          :cell-style="{ 'text-align': 'center' }"
          height= "390"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @row-click="handleClickTableRow"
          :header-cell-style="{ background: '#F7F9FC' }"
        >
          <el-table-column type="selection" width="50" align="center"></el-table-column>
          <el-table-column prop="customName" label="客户姓名" align="center"> </el-table-column>
          <el-table-column prop="subStatusName" label="任务状态" align="center"> </el-table-column>
          <el-table-column prop="tel1" label="手机号" align="center"> </el-table-column>
          <el-table-column prop="nurseName" label="医护人员" align="center"> </el-table-column>
          <!-- <el-table-column prop="city" label="客服管家" align="center"> </el-table-column> -->
          <el-table-column prop="tasktypeName" label="上门类型" align="center"> </el-table-column>
          <el-table-column prop="updateTime" label="更新时间" align="center"> </el-table-column>
          <el-table-column label="操作" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button @click="addNew(scope.row, 2)" type="text" size="small" class="el_btn">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 页码 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[7, 14, 21, 28]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </el-card>
    <theDoorDialog @closeTheDoor="closeTheDoor" v-if="showMailDialog" :dates="addNewdata"></theDoorDialog>
  </div>
</template>

<script>
import theDoorDialog from '@/views/appointmentManagement/components/theDoorDialog'
import { postRequestAnthor } from '@/api/index'
export default {
  data() {
    return {
      form: {
        customerPhone: '',
        customerName: '',
        date1: '',
        region: '',
      },
      tableData: [],
      multipleSelection: [],
      searchlist: [],
      showMailDialog: false,
      currentPage: 1,
      total: 0,
      addNewdata: '',
      loading: true,
      pagesize: 7,
      disabledFalg:false
    }
  },
  components: { theDoorDialog },
  created() {
    this.loaddata(this.pagesize, this.currentPage)
    // 请求数据失败
    setTimeout(() => {
      this.loading = false
    }, 6000)
  },
  methods: {
    // 获取页面数据方法
    loaddata(pageSize, pageNo) {
      postRequestAnthor('/samplingComputer/querySubscribePageList', {
        status: [3, 4, 5, 6],
        subStatus: '',
        samplingType: [1],
        pageSize: pageSize,
        pageNo: pageNo,
      })
        .then(res => {
          console.log(res);
          this.tableData = res.data
          this.total = res.count
          this.loading = false
          // console.log(this.tableData)
        })
        .catch(() => {
          console.log('获取页面数据失败')
        })
    },
    // 每页数据条数
    handleSizeChange(val) {
      this.loading = true
      this.loaddata(val , this.currentPage)
    },
    // 当前页
    handleCurrentChange(val) {
      this.loading = true
      this.loaddata(this.pagesize , val)
    },
    // 选中项
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 点击行选中
    handleClickTableRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    add() {
      this.showAddReferral = true
    },
    // 查询
    search() {
      // console.log(this.form.region)
      this.loading = true
        this.disabledFalg = true
        setTimeout(()=>{
          this.disabledFalg = false
        },2000)
        postRequestAnthor('/samplingComputer/querySubscribePageList', {
          status: [3, 4, 5, 6],
          subStatus: this.form.region,
          samplingType: [1],
          pageSize: '7',
          pageNo: '1',
          customerName: this.form.customerName ? this.form.customerName : '',
          customerTel: this.form.customerPhone ? this.form.customerPhone : '',
          samplStartTime: this.form.date1 ? this.form.date1[0] : '',
          samplEndTime: this.form.date1 ? this.form.date1[1] : '',
        }).then(res => {
          // console.log(res);
          this.loading = false
          this.tableData = res.data
          this.total = res.data.length
        }).catch(()=>{
          this.loading = false
        })
    },
    // 详情
    addNew(row) {
      this.showMailDialog = true
      this.addNewdata = row
    },
    closeTheDoor() {
      this.customerPhone = ''
      this.customerName = ''
      this.date1 = ''
      this.region = ''
      this.showMailDialog = false
      this.loaddata(this.pagesize, this.currentPage)
    },
    // 删除
    handleDelete() {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            let dellist = this.multipleSelection.map(obj => obj.id)
            if (dellist.length === 1) {
              postRequestAnthor('/samplingComputer/deleteSampling', { id: dellist[0].id })
            } else {
              postRequestAnthor('/samplingComputer/deleteSampling', { id: dellist })
            }
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.loaddata([3, 4, 5, 6], 1)
          })
          .catch(() => {
            console.log('删除')
            this.$message({
              type: 'warning',
              message: '已取消删除',
            })
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-right: -20px !important;
}
.organ-management-page {
  background: transparent;
}
.block_page {
  margin-top: 20px;
}
.el-input,
.el-select {
  width: 80%;
}
.Selectnum{
  padding:6px 30px;
  border:1px solid #BFCBF4;
  font-size: 12px;
  color:#666;
  border-radius: 5px;
  margin-bottom:10px;
}
// .el_btn:hover {
//   background-color: pink;
// }
</style>
